import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PortableText from '@sanity/block-content-to-react';
import * as styles from './Questions.module.scss';
import Wrapper from '../Wrapper/Wrapper';

const Questions = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allSanityQuestions(sort: { order: ASC, fields: title }) {
            nodes {
              ...QuestionsData
            }
          }
        }
      `}
      render={(data) => {
        const productList = data.allSanityQuestions.nodes.map(
          ({ id, title, _rawAnswer }) => {
            return (
              <div key={id} className={styles.line}>
                <div className={styles.question}>{title}</div>
                <div className={styles.answer}>
                  <PortableText blocks={_rawAnswer} />
                </div>
              </div>
            );
          }
        );
        return (
          <div className={styles.questions}>
            <Wrapper>
              <div className={styles.text}>
                <h2>Ofte stilte spørsmål</h2>
              </div>
              <div className={styles.questionGrid}>{productList}</div>
            </Wrapper>
          </div>
        );
      }}
    />
  );
};

export default Questions;
