// extracted by mini-css-extract-plugin
export var amount = "Calculator-module--amount--6b2oM";
export var buttonContainer = "Calculator-module--buttonContainer--3JiQc";
export var calculator = "Calculator-module--calculator--qWx7w";
export var explenation = "Calculator-module--explenation--siL++";
export var horizontalSlider = "Calculator-module--horizontal-slider--VJKcL";
export var main = "Calculator-module--main--VvVEE";
export var mobileAmount = "Calculator-module--mobileAmount--1esmJ";
export var name = "Calculator-module--name--KPco2";
export var profit = "Calculator-module--profit--bteVu";
export var slider = "Calculator-module--slider--hleI6";
export var sliderContainer = "Calculator-module--sliderContainer--XLQO6";
export var sliderList = "Calculator-module--sliderList--SymkG";
export var summary = "Calculator-module--summary--I-zAx";
export var thumb = "Calculator-module--thumb--WEKs8";
export var track = "Calculator-module--track--n70SA";