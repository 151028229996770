import React, { useState, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import * as styles from './Calculator.module.scss';
import Button from '../Button/Button';
import formatNumber from '../../utils/formatNumber';
import classNames from 'classnames';
import ReactSlider from 'react-slider';

const Calculator = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          sanityCalculator(_id: { eq: "calculator" }) {
            id
            maxValue
            maxValuePeople
            startValuePeople
            productLines {
              startValue
              relatedProduct {
                id
                profitt
                price
                active
                title
              }
            }
          }
        }
      `}
      render={(data) => {
        const emptyInventory = {};
        data.sanityCalculator.productLines.forEach((product) => {
          if (product.relatedProduct.active !== false) {
            emptyInventory[product.relatedProduct.id] = {
              name: product.relatedProduct.title,
              profitt: product.relatedProduct.profitt,
              amount: product.startValue,
            };
          }
        });
        return (
          <CalculatorElement
            emptyInventory={emptyInventory}
            calculatorData={data.sanityCalculator}
          />
        );
      }}
    />
  );
};

const CalculatorElement = ({ emptyInventory, calculatorData }) => {
  const [people, setPeople] = useState(1);
  const [inventory, setInventory] = useState(emptyInventory);
  const [totalProfit, setTotalProfit] = useState(0);

  const updateInventory = (id, newAmount) => {
    const updatedItem = inventory[id];
    updatedItem.amount = parseInt(newAmount);
    setInventory((prevState) => {
      return {
        ...prevState,
        [id]: updatedItem,
      };
    });
  };

  useEffect(() => {
    let newProfitt = 0;
    const inventoryKeys = Object.keys(inventory);
    for (const id of inventoryKeys) {
      newProfitt += inventory[id].amount * inventory[id].profitt;
    }
    newProfitt *= people;
    setTotalProfit(newProfitt);
  }, [inventory, people]);

  const sliderList = calculatorData.productLines.map(
    ({ relatedProduct, startValue }) => {
      return (
        <div key={relatedProduct.id} className={styles.sliderContainer}>
          <div className={styles.name}>
            {relatedProduct.title}
            <span className={styles.mobileAmount}>
              : {inventory[relatedProduct.id].amount}
            </span>
          </div>
          <div className={styles.slider}>
            <ReactSlider
              className={styles.horizontalSlider}
              thumbClassName={styles.thumb}
              trackClassName={styles.track}
              min={0}
              max={calculatorData.maxValue}
              defaultValue={startValue}
              onChange={(newValue) => {
                updateInventory(relatedProduct.id, newValue);
              }}
            />
          </div>
          <div className={styles.amount}>
            {inventory[relatedProduct.id].amount}
          </div>
        </div>
      );
    }
  );

  return (
    <div className={styles.calculator}>
      <div className={styles.sliderList}>
        <div className={classNames(styles.sliderContainer, styles.main)}>
          <div className={styles.name}>
            Personer<span className={styles.mobileAmount}>: {people}</span>
          </div>
          <div className={styles.slider}>
            <ReactSlider
              className={styles.horizontalSlider}
              thumbClassName={styles.thumb}
              trackClassName={styles.track}
              min={1}
              max={calculatorData.maxValuePeople}
              defaultValue={calculatorData.startValuePeople}
              onChange={(newValue) => {
                setPeople(newValue);
              }}
            />
          </div>
          <div className={styles.amount}>{people}</div>
        </div>
        {sliderList}
      </div>
      <div className={styles.summary}>
        <div className={styles.buttonContainer}>
          <Button variant={'secondary'} to={'/bestill'}>
            Bestill nå
          </Button>
        </div>
        <div className={styles.profit}>
          <div className={styles.explenation}>Dere tjener:</div>
          <div className={styles.amount}>{formatNumber(totalProfit)}</div>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
