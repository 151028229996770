import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PortableText from '@sanity/block-content-to-react';
import * as styles from './PerksList.module.scss';
import {
  LocalShippingOutlined,
  LoyaltyOutlined,
  CheckCircleOutlined,
} from '@material-ui/icons';

const PerksList = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          sanityTexts(_id: { eq: "texts" }) {
            perks {
              _rawDescription(resolveReferences: { maxDepth: 10 })
              _key
              title
              icon
            }
          }
        }
      `}
      render={(data) => {
        const perkList = data.sanityTexts.perks.map(
          ({ _key, icon, title, _rawDescription }) => {
            return (
              <div key={_key} className={styles.perk}>
                <div className={styles.iconContainer}>
                  {icon === 'truck' && (
                    <LocalShippingOutlined className={styles.icon} />
                  )}
                  {icon === 'box' && (
                    <LoyaltyOutlined className={styles.icon} />
                  )}
                  {icon === 'check' && (
                    <CheckCircleOutlined className={styles.icon} />
                  )}
                  {icon === undefined && (
                    <CheckCircleOutlined className={styles.icon} />
                  )}
                </div>
                <div className={styles.text}>
                  <h2 className={styles.title}>{title}</h2>
                  <div className={styles.description}>
                    <PortableText blocks={_rawDescription} />
                  </div>
                </div>
              </div>
            );
          }
        );
        return <div className={styles.perkGrid}>{perkList}</div>;
      }}
    />
  );
};

export default PerksList;
