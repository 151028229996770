import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import Hero from '../components/Hero/Hero';
import RefrenceList from '../components/RefrenceList/RefrenceList';
import Questions from '../components/Questions/Questions';
import ContactSection from '../components/ContactSection/ContactSection';

import SplitSection from '../components/SplitSection/SplitSection';
import Calculator from '../components/Calculator/Calculator';
import PerksList from '../components/PerksList/PerksList';

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Hero
        block={data.sanityTexts._rawHero}
        theme={'dark'}
        showForm={true}
        maximize={true}
        background={
          data.sanityTexts.background &&
          data.sanityTexts.background.asset.gatsbyImageData
        }
      />
      <SplitSection hero={false} left={<Calculator />} right={<PerksList />} />
      <RefrenceList />
      <Questions />
      <ContactSection
        block={data.sanityTexts._rawBuy}
        hero={false}
        askType={true}
      />
    </Layout>
  );
};

export const query = graphql`
  {
    sanityTexts(_id: { eq: "texts" }) {
      _rawHero(resolveReferences: { maxDepth: 10 })
      _rawBuy(resolveReferences: { maxDepth: 10 })
      background {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
  }
`;

export default IndexPage;
