import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from '@sanity/block-content-to-react';
import * as styles from './RefrenceList.module.scss';
import Wrapper from '../Wrapper/Wrapper';

const RefrenceList = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allSanityRefrences {
            nodes {
              ...RefrencesData
            }
          }
          sanityTexts(_id: { eq: "texts" }) {
            _rawPartners(resolveReferences: { maxDepth: 10 })
          }
        }
      `}
      render={(data) => {
        const text = data.sanityTexts._rawPartners;
        const productList = data.allSanityRefrences.nodes.map(
          ({ id, link, image }) => {
            return (
              <div key={id} className={styles.refrence}>
                <a href={link} target={'_blank'} rel="noreferrer">
                  <GatsbyImage
                    className={styles.image}
                    image={image.asset.gatsbyImageData}
                    alt={`${image.alt}`}
                  />
                </a>
              </div>
            );
          }
        );
        return (
          <div className={styles.refrences}>
            <Wrapper>
              <div className={styles.text}>
                <PortableText blocks={text} />
              </div>
              <div className={styles.refrenceGrid}>{productList}</div>
            </Wrapper>
          </div>
        );
      }}
    />
  );
};

export default RefrenceList;
